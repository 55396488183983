body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background-color: #000; /* Set the background color of the page to black */
}

html {
  height: 100%;
}

.App {
  text-align: left;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.new-header-class {
  position: relative;
  top: 0;
  z-index: 100;
  width: 85%;
  height: 28vh;
  padding: 20px;
  margin: auto;
  text-align: center;
  color: whitesmoke;
  background-size: cover;
  background-position: center;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.new-header-class h1,
.new-header-class p {
  display: block;
  background-color: black;
  text-align: left;
  padding: 2px 2px;
}

.gif-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.gif-row img {
  width: auto;
  max-height: 200px;
  margin: 0 10px;
}

.poapbcrlwinter-gif {
  height: 160px;
  width: auto;
  border-radius: 50%;
}

@media (max-width: 600px) {
  .gif-row img {
    max-height: 150px;
    margin: 0 10px;
    padding-top: 10px;
  }

  .poapbcrlwinter-gif {
    height: 145px;
  }
}


.page-description p {
  background-color: black;
  text-align: left;
  padding: 2px 2px;
  margin: 0;
}


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px solid whitesmoke; /* Add a white smoke border */
  padding: 20px;
  border-radius: 25px;
  width: 85%;
  height: auto;
  background-color: black; /* Change background color to black */
  box-shadow: none;
  color: #FFC466;
  margin-top: 0;
}


.connected-message {
  width: 500px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
}


.App-link {
  color: #29B9DB;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Pop-Up CSS-Styles */

.popup {
  border: 2px solid #ccc;
  padding: 10px;
  background-color: #003f3f;
  border-radius: 5px;
  max-width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999; /* Add a higher z-index value */
}


.popup-header {
  font-weight: bold;
  font-size: 14px;
  color: #FFC466;
}

.popup-icon {
  font-weight: bold;
  color: #FFC466;
  margin-right: 5px;
}

.popup h3 {
  font-weight: bold;
  color: #FFC466;
}

.popup-list {
  list-style: none;
  padding-left: 0;
  text-align: center;
}

.popup-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  word-wrap: break-word;
}

.popup-button {
  margin-top: 10px;
}

